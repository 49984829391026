import React from "react";
import { Link } from "react-router-dom";

const HomeButton = () => {
  return (
    <div>
      <Link
        className="nav-text"
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "20%",
          color: "white",
          backgroundColor: "black",
          padding: "10px",
          borderRadius: "10px",
          textDecoration: "none",
          //   display: "flex",
          margin: "10px",
          position: "fixed",
          left: 0,
          right: 0,
          //   bottom: ,
          top: 0,
        }}
        to="/"
      >
        Home
      </Link>
    </div>
  );
};

export default HomeButton;
