import React, { useEffect, useState } from "react";
import "./style.css";
import hcbgImage from "../../assets/images/horizontal.png";
import vcbgImage from "../../assets/images/vertical.png";
import HomeButton from "../../components/HomeButton";
import { GrNext, GrPrevious } from "react-icons/gr";

const EnglishText = () => {
  const [view, setView] = useState("");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setView("mobile");
      } else {
        setView("desktop");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let backgroundStyle;
  if (view === "mobile") {
    backgroundStyle = {
      backgroundImage: "url(" + vcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  } else {
    backgroundStyle = {
      backgroundImage: "url(" + hcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  }

  const [index, setIndex] = useState(0);

  const englishtext = [
    ` 
   Anek roope avtari, bhom utaran bhaar,
   Aavi shakti Ishwari, kamkhari khodiyar.,

   Jaagat janeta aap cho, dayalu ne daata,
   Bhav sagar thaki tarva, kodal ek aadhar.

   Nav khando maa neja farke, dase disha ye tara naam,
   Bhagto tani prati paal che, tu khamkhari Khodiyar.

   Galghare pratham vaas karyo, pragatiya shakti avtaar,
   Darshan didha raa navghane, Khamkhari Khodiyar. `,

    `
   Tu tataniya dhara wali, darshan thi shukh denar,
   Taalti dukh jo anek na, khamkhari khodiyar.

   Sorat bhoomi sohamani, matel dhara ma vaas,
   Marda tu uthad thi, Maa Khamkhari Khodiyar.

   Khodal khadag dhari maat, vidya vaad vaali maat,
   Parcha puriya te ghana, thayee jagat ma vikhyat.

   Dungre dungre diva bale tara maa Khodiyar,
   Trishul tejasvi haath ma, divya jeno chamkar.`,

    `
   Trishul dharini Khodali, karti tu khamkhar,
   Lobadyadi aai tu, Saav ne sukh denar.

   Magar per savari kari, padhare Khodal maat,
   Je bhave, je je bhaje, tane darshan de shakshat.

   Dhara dhara ma vaas taro, trishul kariyu nishan,
   Giri dungre vaas taro, parcha tara mahan.

   Vangiya meenu talva, avtariya charan gher,
   Kariyo ma te kul uddhar, Khamkhari Khodiyar.`,

    `
   Janamiya mamdiya na ghare, Cha bheno ni sangat,
   Lagi khodi keta tane, pan thai tu jaag vikhiyat.

   Khodal keri sahai thi, varudi karti kaaj,
   Parcha kai jova madiya, raan nav ghan ne saaj.

   Khodal teri sahai thi, jo dariyo odangai,
   Samre je je bhaav thi, kaam tena safal thai.

   Darshan didha rai ne, Khodal maa ye sakshat,
   Dhanye bani gayu jeevan, jagh ma thayo vikhyat.`,

    `
   Tran varas ni umere, parcha purti mai,
   Hati varodi vanjani, thai dujti gaai.

   Sona, rupa ni chadi per, laal dhaja anupam,
   Puje Khodiyar maat ne valbhipur no bhug.

   Khodal keri krupa ye, nirogi thayo raj kumar,
   Rog, dog saav chali gaya. Thayu mukh tej ambar.

   Shihor kera dungare, karyo khodal vaas,
   Rank rai sav naman kare, maa puure sav ni aash.`,

    `
   Nek tek vrat shradha thi,Meherban khodal thai,
   Pangu valan jaan sudh ne,jo chadhaiyo dungar shan mai.

   Ae pratapi maa khodal ye karyo prachand padkar,
   Dunaviyo dundhadi nath ne, parchadi tu khodiyar.

   Ae dhune mandiyo dundhva, dhundhadi jogindar,
   Maaye vagdaviya dakla, dhune dhandhal per.

   Kolambha sadh bhagi, kamlai dungar nu naam,
   Darshan thi dukhda tale, Maa khodiyar nu dham.`,

    `
   Hatti singh kumatiyo thayo, Atyachar karyo aamap,
   Maa kanyaye tav krupaye thi, bhasma karyo ae bhup.

   Tataniya na dhara paase, khodal karya dham,
   Bhavnagar na nritipyo no maa karti sada kaam.

   Chinta, vignah vinashini, trishul hast dharat,
   Ae khodal maa dayali, tu bhakt rakhsha karat.

   Maa khodal, maa dayali jone karti sahai,
   Sarnagath rakshani, jone karti mai.
   `,
    `
   Aandha ne dekhta kare, vanjiya ne aape baal,
   Parcha apram par khodal, tu che din dayal

   Khodal khodal je kahe, ne dhare nirantar dhyan,
   Teni sahaye sarvda, rahe tu khodal maat.

   Din vatsalya khodiyar ni,krupa nazar jo thai too,
   Truno meru bane, mungo mangal gai.

   Momadiya ne baal ne, bhajta patak jai,
   Paap sarve tena tale, jeevan unaat thai.
   `,

    `
   Aadhi vyadhi saav pale, Khodal ne darbar,
   Asha sav puri kare,khamkhari khodiyar.

   Dhabadiyali maavdi, khapar vaadi khodiyar,
   Khamkharo jo kare to, bhav na dukhda jai.

   Khodal sav ni mavdi, sankate kare sahai,
   Tene bharose navdi, utre paar sadai.

   Sahai jene khodiyar ni,mansa puran thai,
   Har pal hazar rahe, khamkhari khodiyal maa.
   `,
    `
    Langada bane saza narva, maa khodal ne pratap,
    Rogi kaik thai nirogi. Maa khodal ni pratap.

   Lula, langada ane dukhiya, aavta maa ne dwar,
   Het thi hasi raji kari, khodal karti vahar.

   Khodal saav ni mavdi, vipte karje sahai,
   Birud taaru jai na, bharje na pacho pai.

   Maa ni leela no nahi par, jena ther ther ther dhaam,
   Maa na guun gavano nai par, sarve haaide aenu naam.
   || Maa khodiyar, Maa khodiyar, Maa khodiyar || 
   `,
  ];

  const handleNext = () => {
    setIndex((index + 1) % englishtext.length);
  };

  const handlePrev = () => {
    setIndex(index === 0 ? englishtext.length - 1 : index - 1);
  };

  return (
    <div className="Home" style={backgroundStyle}>
      <center>
        <HomeButton />
      </center>
      <div className="container">
        <div>
          <button className="prev" onClick={handlePrev}>
            <GrPrevious />
          </button>

          <div className="card-container">
            <span className="card" style={{ whiteSpace: "pre-wrap" }}>
              <p
                style={{
                  textAlign: "center",
                }}
                className="text"
              >
                {englishtext[index]}
              </p>
            </span>
          </div>

          {/* <div className={"card"}>{englishtext[index]}</div> */}

          <button className="next" onClick={handleNext}>
            <GrNext />
          </button>
        </div>
        <div className="dot-indicators">
          {englishtext.map((text, i) => (
            <span
              key={text}
              className={`dot ${i === index ? "active" : ""}`}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnglishText;
