import React, { useEffect, useState } from "react";
import "./style.css";
import hcbgImage from "../../assets/images/horizontal.png";
import vcbgImage from "../../assets/images/vertical.png";
import HomeButton from "../../components/HomeButton";
import { GrNext, GrPrevious } from "react-icons/gr";

const GujaratiText = () => {
  const [view, setView] = useState("");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setView("mobile");
      } else {
        setView("desktop");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let backgroundStyle;
  if (view === "mobile") {
    backgroundStyle = {
      backgroundImage: "url(" + vcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  } else {
    backgroundStyle = {
      backgroundImage: "url(" + hcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  }

  const [index, setIndex] = useState(0);

  const GujaratiText = [
    `
અનેક રૂપે અવતરી, ભોમ ઉતારણ ભાર, 
આવી શક્તિ ઈશ્વરી, ખમકારી ખોડિયાર.

જગત જનેતા આપ છો, દયાળુ ને દાતાર, 
ભવસાગર થકી તરવા, ખોડલ એક આધાર.

નવ ખંડોમાં નેજા ફરકે, દશે દિશાએ તારાં નામ, 
ભક્તો તણી પ્રતિપાલ છે, તું ખમકારી ખોડિયાર.

ગળધરે પ્રથમ વાસ કર્યો, પ્રગટ્યા શક્તિ અવતાર, 
દર્શન દીઘા રા નવઘણને, ખમકારી ખોડિયાર.`,
    `
તું તાંતણિયા ધરાવાળી, દર્શનથી સુખ દેનાર, 
ટાળતી દુઃખ જો અનેકના, ખમકારી ખોડિયાર.

સોરઠ ભૂમિ સોહામણી, માટેલ ધરામાં વાસ, 
મડદાં તું ઉઠાડતી, મા ! ખમકારી ખોડિયાર.

ખોડલ ખડગધારી માત, વિદ્યવડવાળી માત, 
પરચા પૂર્યા તે ઘણા, થઈ જગતમાં વિખ્યાત.

ડુંગરે ડુંગરે દીવા બળે, તારા મા ખોડિયાર, 
ત્રિશૂળ તેજસ્વી હાથમાં, દિવ્ય જેનો ચમકાર.`,
    `
ત્રિશૂળધારિણી ખોડલી, કરતી તું ખમકાર, 
લોબડીઆળી આઈ તું, સહુને સુખ દેનાર.

મગર ઉપર સવારી કરી, પધારે ખોડલ માત, 
જે ભાવે જે જે ભજે, તેને દર્શન દે સાક્ષાત્.

ધરા ધરામાં વાસ તારો, ત્રિશૂળ કર્યું નિશાન, 
ગિરિ-ડુંગરે વાસ તારો, પરચા તારા મહાન.

વાંઝિયા મેણું ટાળવા, અવતર્યા ચારણ ઘેર, 
કર્યો મા તેં કુળ ઉદ્ઘાર, ખમકારી ખોડિયાર.`,
    `
જન્મ્યાં મોમડિયાને ઘેર, છ બહેનોની સંગાત, 
લાગી ખોડી કે'તા તને, પણ થઈ તું જગવિખ્યાત.

ખોડલ કેરી સહાયથી, વરુડી કરતી કાજ, 
પરયાં કંઈ જોવા મળ્યા, રા નવઘણને સાચ.

ખોડલ કેરી સહાયથી, જો દરિયો ઓળંગાય, 
સમરે જે જે ભાવથી, કામ તેના સફળ થાય.

દર્શન દીઘા (રા) રાયને, ખોડલ માએ સાક્ષાત્, 
ધન્ય બની ગયું જીવન, જગમાં થયો વિખ્યાત.`,
    ` 
ત્રણ વરસની ઉંમરે, પરચા પૂરતી માય, 
હતો વરોળી વાંઝણી, થઈ દૂઝતી ગાય.

સોના-રૂપાની છડી પર, લાલ ધજા અનુપમ, 
પૂજે ખોડિયાર માતને, વલ્લભીપુરનો ભૂપ.

ખોડલ કેરી કૃપાએ, નીરોગી થયો રાજકુમાર, 
રોગ-દોગ સૌ ચાલી ગયા, થયું મુખ તેજ અંબાર.

શિહોર કેરા ડુંગરે, કર્યો ખોડલ વાસ, 
રંક રાય સૌ નમન કરે, મા પૂરે સૌની આશ.`,
    `
નેક ટેક વ્રત શ્રદ્ઘાથી, મે'રબાન ખોડલ થાય, 
પંગુ વરજાંગ સુતને જો, ચડાવ્યો ડુંગર ક્ષણમાંય.

એ પ્રતાપી મા ખોડલે, કર્યો પ્રચંડ પડકાર, 
ધુણાવ્યો ધુંધળીનાથને, પરચાળી તું ખોડિયાર.

એ ધૂણે મંડ્યો ધૂણવા, ધૂંધણી જોગંદર, 
માએ વગડાવ્યાં ડાકલાં, ધૂણે ધાંધલપર.

કોળાંભા સદભાગી, કમળાઈ ડુંગરનું નામ, 
દર્શનથી દુઃખડાં ટળે, મા ખોડિયારનું ધામ.`,
    `
હઠીસિંગ કુમતિયો થયો, અત્યાચાર કર્યો અમાપ, 
મા કન્યાએ તવ કૃપાથી, ભસ્મ કીધો એ ભૂપ.

તાંતણિયા ધરા પાસે, ખોડલે કર્યા ધામ, 
ભાવનગર નૃપતિઓનાં, મા કરતી સદા કામ.

ચિંતા વિઘ્ન વિનાશિની, ત્રિશૂળ હસ્ત ધરંત, 
હે ખોડલ ! મા દયાળી, તું ભક્ત-રક્ષા કરંત.

મા ખોડલ, મા દયાળી, જોને કરતી સહાય, 
શરણાગત-રક્ષા નિત, જોને કરતી માય.`,
    `
અંધને દેખતા કરે, વાંઝિયાને આપે બાળ, 
પરચા અપરંપાર ખોડલ, તું છે દીનદયાળ.

ખોડલ ખોડલ જે કહે, ને ઘરે નિરંતર ઘ્યાન, 
તેની સહાયે સર્વદા રહે, તું ખોડલ માત.

દીન વત્સલ ખોડિયારની, કૃપા નજર જો થાયતો, 
તૃણનો મેરુ બને, મૂંગો મંગળ ગાય.

મોમડિયાની બાળને, ભજતાં પાતક જાય, 
પાપ સરવ તેનાં ટળે, જીવન ઉન્નત થાય.`,
    ` 
આધી-વ્યાધી સહ પળે, ખોડલને દરબાર, 
આશા સહુ પૂરી કરે, ખમકારી ખોડિયાર.

ધાબડીયાળી માવડી, ખપ્પરવાળી ખોડિયાર, 
ખમકારો જો કરે તો, ભવનાં દુ:ખડાં જાય.

ખોડલ સૌની માવડી, સંકટે કરે સહાય, 
તેને ભરોંસે નાવડી, ઊતરે પાર સદાય.

સહાય જેને ખોડિયારની, મનસા પુરણ થાય, 
હર પળે હાજર રહે એ, ખમકારી ખોડલ માય.`,
    `
લંગડા બને સાજા નરવાં, મા ખોડલને પ્રતાપ, 
રોગી કૈંક થાય નિરોગી, મા ખોડલને પ્રતાપ.

લૂલાં લંગડાં ને દુ:ખિયાં, આવતા માને દ્વાર, 
હેતથી હસી રાજી કરી, ખોડલ કરતી વહાર.

ખોડલ સૌની માવડી, વિપત્તે કરજે સહાય, 
બિરુદ તારું જાય ના, ભરજે ન પાછો પાય.

'મા' ની લીલાનો નહિ પાર, જેના ઠેર ઠેર ધામ, 
'મા' ના ગુણ ગાવાનો નહિ પાર,સર્વ હૈયે એનું નામ.
|| મા ખોડીયાર, મા ખોડીયાર, ખોડીયાર માતકી જાય ||`,
  ];

  const handleNext = () => {
    setIndex((index + 1) % GujaratiText.length);
  };

  const handlePrev = () => {
    setIndex(index === 0 ? GujaratiText.length - 1 : index - 1);
  };

  return (
    <div className="Home" style={backgroundStyle}>
      {/* <NavBar /> */}
      <center>
        <HomeButton />
      </center>
      <div className="container">
        <div>
          <button className="prev" onClick={handlePrev}>
            <GrPrevious />
          </button>

          <div className="card-container">
            <span className="card" style={{ whiteSpace: "pre-wrap" }}>
              <p
                style={{
                  textAlign: "center",
                }}
                className="text"
              >
                {GujaratiText[index]}
              </p>
            </span>
          </div>

          {/* <div className={"card"}>{GujaratiText[index]}</div> */}

          <button className="next" onClick={handleNext}>
            <GrNext />
          </button>
        </div>
        <div className="dot-indicators">
          {GujaratiText.map((text, i) => (
            <span
              key={text}
              className={`dot ${i === index ? "active" : ""}`}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GujaratiText;
