import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import GujaratiSound from "./pages/GujaratiSound";
import GujaratiText from "./pages/GujaratiText";
import EnglishSound from "./pages/EnglishSound";
import EnglishText from "./pages/EnglishText";
import About from "./pages/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/gujaratisound",
    element: <GujaratiSound />,
  },
  {
    path: "/gujaratitext",
    element: <GujaratiText />,
  },
  {
    path: "/englishsound",
    element: <EnglishSound />,
  },
  {
    path: "/englishtext",
    element: <EnglishText />,
  },
  {
    path: "/about",
    element: <About />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
