import "./style.css";
import hcbgImage from "../../assets/images/horizontal.png";
import vcbgImage from "../../assets/images/vertical.png";
import React, { useEffect, useState } from "react";
// import appsrorebadge from "../../assets/images/appstore.svg";
import HomeButton from "../../components/HomeButton";

const About = () => {
  const [view, setView] = useState("");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setView("mobile");
      } else {
        setView("desktop");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let backgroundStyle;
  if (view === "mobile") {
    backgroundStyle = {
      backgroundImage: "url(" + vcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  } else {
    backgroundStyle = {
      backgroundImage: "url(" + hcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  }

  return (
    <>
      <div
        className="Home"
        // style={}
        style={backgroundStyle}
      >
        <center>
          <HomeButton />
        </center>
        <div className="about-container">
          <div className="about-content">
            <h1>About</h1>
            <div className="">
              <p>
                The Khodiyar Chalisa is a devotional hymn dedicated to the Hindu
                goddess Khodiyar. It is believed to be a powerful tool for
                invoking her blessings and protection. The chalisa typically
                consists of 40 verses in praise of the goddess and her various
                attributes. The chalisa is typically recited by her devotees as
                a form of worship and for the purpose of seeking her blessings
                for health, wealth, and success in life. Some people also recite
                it to get rid of negative energy and obstacles in life. It is
                believed that the recitation of the Khodiyar Chalisa with faith
                and devotion can bring peace, prosperity, and success in one's
                life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
