import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import hcbgImage from "../../assets/images/horizontal.png";
import vcbgImage from "../../assets/images/vertical.png";
import videoFile from "../../assets/video/EnglishSound.mp4";
import HomeButton from "../../components/HomeButton";
import { MdReplay10, MdForward10 } from "react-icons/md";
import { HiPlayPause, HiOutlineSpeakerWave } from "react-icons/hi2";

const EnglishSound = () => {
  const [view, setView] = useState("");

  // Toggle mute unmute

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setView("mobile");
      } else {
        setView("desktop");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let backgroundStyle;
  if (view === "mobile") {
    backgroundStyle = {
      backgroundImage: "url(" + vcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  } else {
    backgroundStyle = {
      backgroundImage: "url(" + hcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  }
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleForward = () => {
    videoRef.current.currentTime += 10;
  };

  const handleBackward = () => {
    videoRef.current.currentTime -= 10;
  };
  const handleMute = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
    } else {
      videoRef.current.muted = true;
    }
  };
  useEffect(() => {
    videoRef.current.autoplay = true;
  }, []);
  return (
    <div className="Home" style={backgroundStyle}>
      <center>
        <HomeButton />
      </center>
      {/* <NavBar /> */}
      {/* <MyButtons /> */}
      <div className="container">
        <div>
          <div className="card-container">
            <span className="card-video" style={{ whiteSpace: "pre-wrap" }}>
              <video
                className="custom-video"
                controls={false}
                autoPlay
                ref={videoRef}
              >
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </span>
          </div>

          {/* <div className={"card"}>{EnglishSound[index]}</div> */}
        </div>
        <div className="custom-controls">
          <button onClick={handleBackward}>
            <MdReplay10 />
          </button>
          <button onClick={handlePlayPause}>
            <HiPlayPause />
          </button>
          <button onClick={handleForward}>
            <MdForward10 />
          </button>
          <button onClick={handleMute}>
            <HiOutlineSpeakerWave />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnglishSound;
