import "./style.css";
import hcbgImage from "../../assets/images/horizontal.png";
import vcbgImage from "../../assets/images/vertical.png";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";
import appsrorebadge from "../../assets/images/appstore.svg";

const Home = () => {
  const [view, setView] = useState("");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setView("mobile");
      } else {
        setView("desktop");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let backgroundStyle;
  if (view === "mobile") {
    backgroundStyle = {
      backgroundImage: "url(" + vcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  } else {
    backgroundStyle = {
      backgroundImage: "url(" + hcbgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  }

  return (
    <div
      className="Home"
      // style={}
      style={backgroundStyle}
    >
      <div className="button-container">
        <Link className="button" to="/gujaratisound">
          Gujarati Sound
        </Link>
        <Link className="button" to="/gujaratitext">
          Read in Gujarati
        </Link>
        <Link className="button" to="/englishsound">
          English Sound
        </Link>
        <Link className="button" to="/englishtext">
          Read in English
        </Link>
        <br />
        <br />
        <div className="download-button">
          <a
            className="playstore-btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.khodiyarchalisa.khodiyarchalisaapp"
          >
            <img
              className="playstore-img"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
          <a
            className="appstore-btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/in/app/khodiyaar-chalisa/id1673775247"
          >
            <img
              className="appstore-img"
              alt="Get it on Google Play"
              src={appsrorebadge}
            />
          </a>
        </div>
      </div>
      <div className="button-bottom-right">
        <Link to="/about" className="button">
          <InfoIcon />
        </Link>
      </div>
    </div>
  );
};

export default Home;
